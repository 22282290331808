<template>
  <b-card>
    <div
      v-for="block in camperInfoTabData"
      :key="block.id"
    >
      <b-row>
        <b-col
          sm="12"
          class="d-flex align-items-center mb-2 mt-1"
        >
          <feather-icon
            icon="BookmarkIcon"
            size="16"
          />
          <h4 class="mb-0 ml-75">
            {{ block.name }}
          </h4>
        </b-col>
        <hr class="mt-0 w-100">
      </b-row>

      <b-row
        v-for="item in block.items"
        :key="item.id"
        class="mb-1"
      >
        <b-col
          md="3"
        >
          <h5>
            {{ item.label }}
          </h5>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <span>{{ item.value || 'No value' }}</span>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    camperInfoTabData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>

</style>
