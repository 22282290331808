<template>
  <b-card>
    <b-row>
      <b-col
        cols="6"
      >
        <h5 class="mb-1">
          PC Option
        </h5>
        <div
          v-if="selectedOption.price_option.priceType == 'Full Price'"
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/price.svg')"
            class="mr-1"
          >
          <span>{{ selectedOption.price_option.priceType }}</span>
        </div>

        <div
          v-if="selectedOption.price_option.priceType == 'Percent discount'"
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/price.svg')"
            class="mr-1"
          >
          <span>{{ selectedOption.price_option.priceType }} - {{ selectedOption.price_option.percentDiscount }}%</span>
        </div>

        <div
          v-if="selectedOption.price_option.priceType == 'Dollar discount'"
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/price.svg')"
            class="mr-1"
          >
          <span>{{ selectedOption.price_option.priceType }} - ${{ selectedOption.price_option.dollarDiscount }}</span>
        </div>

        <div
          v-if="selectedOption.price_option.priceType == 'Full Price + Additional Charge'"
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/price.svg')"
            class="mr-1"
          >
          <span>{{ selectedOption.price_option.priceType }} ( + ${{ selectedOption.price_option.additionalCharge }})</span>
        </div>

        <div
          v-if="selectedOption.schedule_option.timeSchedule == 'Charge Immediately'"
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/split.svg')"
            class="mr-1"
          >
          <span>{{ selectedOption.schedule_option.timeSchedule }}</span>
        </div>

        <template v-else>
          <div class="mb-1">
            <img
              :src="require('@/assets/images/svg/split.svg')"
              class="mr-1"
            >
            <span>{{ selectedOption.schedule_option.timeSchedule }} {{ selectedOption.schedule_option.paymentAmountRange.from }}-{{ selectedOption.schedule_option.paymentAmountRange.to }}</span>
          </div>

          <div class="mb-1">
            <img
              :src="require('@/assets/images/svg/pay-date.svg')"
              class="mr-1"
            >
            <span>Pay Date  {{ selectedOption.schedule_option.payDate }}</span>
          </div>
          <div class="mb-1">
            <img
              :src="require('@/assets/images/svg/calendar.svg')"
              class="mr-1"
            >
            <span>Start Date  {{ selectedOption.schedule_option.startDate | formatDate }}</span>
          </div>
          <div class="mb-1">
            <img
              :src="require('@/assets/images/svg/sand-clock.svg')"
              class="mr-1"
            >
            <span>End Date {{ selectedOption.schedule_option.endDate | formatDate }}</span>
          </div>
        </template>
        <div class="mb-1">
          <img
            :src="require('@/assets/images/svg/dollar-sign.svg')"
            class="mr-1"
          >
          <span>Payment method: {{ selectedOption.method.join(', ') }}</span>
        </div>
        <div class="mb-1">
          <img
            :src="require('@/assets/images/svg/dollar-sign.svg')"
            class="mr-1"
          >
          <span>Required Deposit: ${{ selectedOption.price_option.requiredDeposit ? selectedOption.price_option.requiredDeposit : 0 }}</span>
        </div>
      </b-col>

      <b-col
        v-if="applicationPaymentOption"
        cols="6"
      >
        <h5 class="mb-1">
          Selected Parameters by User
        </h5>

        <div
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/split.svg')"
            class="mr-1"
          >
          <span>Amount of payments: {{ applicationPaymentOption.count_of_payments }}</span>
        </div>

        <div class="mb-1">
          <img
            :src="require('@/assets/images/svg/calendar.svg')"
            class="mr-1"
          >
          <span>Start Date: {{ applicationPaymentOption.start_date | formatDate }}</span>
        </div>

        <div class="mb-1">
          <img
            :src="require('@/assets/images/svg/dollar-sign.svg')"
            class="mr-1"
          >
          <span>Payment method: {{ applicationPaymentOption.payment_method }}</span>
        </div>
      </b-col>

      <b-col
        v-else
        cols="6"
      >
        <h5 class="mb-1">
          Selected Parameters by Admin
        </h5>

        <div
          class="mb-1"
        >
          <img
            :src="require('@/assets/images/svg/split.svg')"
            class="mr-1"
          >
          <span>Amount of payments:
            <span v-if="selectedOption.defaults && selectedOption.defaults.paymentAmount">
              {{ selectedOption.defaults.paymentAmount }}
            </span>
          </span>
        </div>

        <div class="mb-1">
          <img
            :src="require('@/assets/images/svg/calendar.svg')"
            class="mr-1"
          >
          <span>Start Date:
            <span v-if="selectedOption.defaults && selectedOption.defaults.startDate">
              {{ selectedOption.defaults.startDate | formatDate }}
            </span>
          </span>
        </div>

        <div class="mb-1">
          <img
            :src="require('@/assets/images/svg/dollar-sign.svg')"
            class="mr-1"
          >
          <span>Payment method:
            <span v-if="selectedOption.defaults && selectedOption.defaults.selectedMethod">
              {{ selectedOption.defaults.selectedMethod }}
            </span>
          </span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import { getFormatDate } from '@/helpers/dateFormater'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  filters: {
    formatDate(value) {
      return getFormatDate(value)
    },
  },
  props: {
    selectedOption: {
      type: Object,
      required: true,
    },
    applicationPaymentOption: {
      type: [Object, null],
      default: () => {},
    },
  },
}
</script>

<style>

</style>
