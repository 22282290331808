<template>

  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card
        no-body
        header-class="border-bottom"
      >
        <template #header>
          <h4 class="d-flex align-items-center mb-0">
            Payment Contract ({{ paymentContract.name }})
            <b-badge
              v-if="applicationPaymentOption"
              variant="light-success"
              pill
              class="ml-2"
              style="fontSize: 12px"
            >
              Selected
            </b-badge>
            <b-badge
              v-else-if="selectedOption"
              variant="light-info"
              pill
              class="ml-2"
              style="fontSize: 12px"
            >
              Waiting for User Confirmation
            </b-badge>
          </h4>
          <feather-icon
            v-if="canSelectPaymentContract"
            icon="EditIcon"
            size="24"
            class="cursor-pointer text-primary"
            @click.stop="showSelectContractModal"
          />
        </template>

        <selected-option-params
          v-if="selectedOption"
          :selected-option="selectedOption"
          :application-payment-option="applicationPaymentOption"
        />

        <add-custom-option
          v-else-if="paymentContract && isAddingCustomOption"
          :payment-contract-id="paymentContract.id"
          :user-id="userId"
          @cancelAdding="isAddingCustomOption = false"
          @updatePaymentContractData="updatePaymentContractData"
        />

        <template
          v-else
        >
          <div class="px-2 py-1">
            Choose an option
            <span
              class="cursor-pointer px-2 text-primary"
              @click="addCustomOption"
            >
              Add custom option
              <feather-icon
                icon="PlusIcon"
                size="14"
                class="text-primary"
              />
            </span>
          </div>
          <b-form-radio-group
            :checked="selectedOption"
            class="px-2"
            stacked
            @change="setSelectedOption"
          >
            <b-form-radio
              v-for="option in paymentContractOptions"
              :key="option.id"
              :value="option"
              class="my-1"
            >
              {{ optionDescription(option) }}
            </b-form-radio>
          </b-form-radio-group>
        </template>

        <template
          v-if="selectedOption"
          #footer
        >
          <b-button
            v-if="canSetPaymentContractParameters"
            variant="outline-primary"
            @click="$bvModal.show('default-payment-option')"
          >
            <feather-icon
              icon="Edit2Icon"
              size="14"
              class="text-primary"
              style="marginRight: 5px"
            />
            <span v-if="applicationPaymentOption || selectedOption.defaults">Change Parameters</span>
            <span v-else>Set Parameters</span>
          </b-button>

          <span
            v-if="canResetPaymentContract"
            class="cursor-pointer text-danger ml-2"
            @click="confirmationModalShow = true"
          >
            <feather-icon
              icon="XIcon"
              size="16"
            />
            Reset Selected Option
          </span>
        </template>

        <SelectPaymentContractModal
          v-if="paymentContract"
          :payment-contract-id="paymentContract.id"
          :application-id="applicationId"
          @selectNewContract="selectNewContract"
        />

        <SetDefaultPaymentOptionModal
          v-if="paymentContract"
          :selected-default-option="selectedOption"
          :user-id="userId"
          :payment-contract-id="paymentContract.id"
          :is-loading="isLoading"
          @setDefaultPaymentOptionParameters="setDefaultPaymentOptionParameters"
        />

        <confirmation-modal
          :toggle-modal="confirmationModalShow"
          :is-loading="isLoading"
          :type="'-option-reset'"
          :title="'Are you sure'"
          :message="'Are you sure you want to reset the selected option?'"
          @close-modal="confirmationModalShow = false"
          @confirm="resetSelectedOption"
        />
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton, BFormRadioGroup, BFormRadio, BBadge, BOverlay,
} from 'bootstrap-vue'

import usePaymentContract from '@/views/components/application-payment-contract-v2/usePaymentContract'

import SelectedOptionParams from '@/views/components/application-payment-contract-v2/SelectedOptionParams.vue'

import AddCustomOption from '@/views/components/application-payment-contract-v2/AddCustomOption.vue'
import SelectPaymentContractModal from '@/views/components/application-payment-contract-v2/SelectPaymentContractModal.vue'
import SetDefaultPaymentOptionModal from '@/views/components/application-payment-contract-v2/SetDefaultPaymentOptionModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { getFormatDate } from '@/helpers/dateFormater'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BCard,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BOverlay,

    SelectedOptionParams,
    AddCustomOption,
    SelectPaymentContractModal,
    SetDefaultPaymentOptionModal,
    ConfirmationModal,
  },
  filters: {
    formatDate(value) {
      return getFormatDate(value)
    },
  },
  data() {
    return {
      isLoading: false,
      paymentContractOptions: [],
      selectedOption: null,
      confirmationModalShow: false,
      isAddingCustomOption: false,
    }
  },
  setup() {
    const {
      updateApplicationPaymentOptionData,
      deleteApplicationPaymentOption,
    } = usePaymentContract()

    return {
      updateApplicationPaymentOptionData,
      deleteApplicationPaymentOption,
    }
  },
  computed: {
    ...mapGetters({
      userId: 'app-application-edit/getUserId',
      applicationId: 'app-application-edit/getApplicationId',
      applicationPaymentOption: 'app-application-edit/getApplicationPaymentOption',
      paymentContract: 'app-application-edit/getPaymentContract',
    }),
    canSelectPaymentContract() {
      return this.$can('select', permissionSubjects.PaymentContract)
    },
    canResetPaymentContract() {
      return this.$can('reset', permissionSubjects.PaymentContract)
    },
    canSetPaymentContractParameters() {
      return this.$can('set', permissionSubjects.PaymentContractParameters)
    },
  },
  watch: {
    paymentContract: {
      deep: true,
      handler(val) {
        this.setPaymentContractOptions(val)
      },
    },
  },
  async created() {
    if (this.paymentContract) {
      this.setPaymentContractOptions(this.paymentContract)
    }
  },
  methods: {
    setPaymentContractOptions(contract) {
      this.paymentContractOptions = JSON.parse(JSON.stringify(contract.options)).filter(item => item.user_id === null || item.user_id === this.userId)
      if (this.applicationPaymentOption) {
        // eslint-disable-next-line eqeqeq
        this.selectedOption = this.paymentContractOptions.find(option => option.id == this.applicationPaymentOption.condition_id)
      } else {
        const selected = this.paymentContractOptions.filter(option => option.status === 1)
        if (selected.length === 1) {
          const [option] = selected
          this.selectedOption = option
        }
      }
    },
    async selectNewContract() {
      await this.resetSelectedOption()
      await this.updatePaymentContractData()
    },
    async updatePaymentContractData() {
      await this.$store.dispatch('app-application-edit/fetchApplication')
    },
    optionDescription(option) {
      let string = ''

      if (option.price_option.priceType) {
        string += `${option.price_option.priceType} - `
        if (option.price_option.priceType === 'Percent discount') {
          string += ` ${option.price_option.percentDiscount}% - `
        }
        if (option.price_option.priceType === 'Dollar discount') {
          string += ` $${option.price_option.dollarDiscount} - `
        }
        if (option.price_option.priceType === 'Full Price + Additional Charge') {
          string += ` $${option.price_option.additionalCharge} - `
        }
      }

      if (option.schedule_option.timeSchedule) {
        string += `${option.schedule_option.timeSchedule} - `
        if (option.schedule_option.timeSchedule === 'Split over') {
          string += ` ${option.schedule_option.paymentAmountRange.from}-${option.schedule_option.paymentAmountRange.to} - `
          if (option.schedule_option.payDate) string += `Pay Date ${option.schedule_option.payDate} - `
          if (option.schedule_option.startDate) string += `Start Date ${getFormatDate(option.schedule_option.startDate)} - `
          if (option.schedule_option.endDate) string += `End Date ${getFormatDate(option.schedule_option.endDate)} - `
        }
      }
      if (option.method) {
        string += option.method.join(', ')
      }

      return string
    },
    showSelectContractModal() {
      this.$bvModal.show('select-payment-contract')
    },
    async selectPaymentContract(contract) {
      this.paymentContractOptions = contract.options.filter(item => item.user_id === null || item.user_id === this.userId)
    },
    async deleteUserSelectedOptionIfExist() {
      if (this.applicationPaymentOption) {
        await this.deleteApplicationPaymentOption(this.applicationPaymentOption.id)
      }
    },
    async updateOptionVisibility(options, status) {
      const queryParams = {
        payment_option_id: options,
        user_id: this.userId,
        status: Number(status),
      }
      await this.updateApplicationPaymentOptionData(queryParams)
    },
    async setSelectedOption(option) {
      this.isLoading = true
      this.selectedOption = option
      await this.deleteUserSelectedOptionIfExist()

      const optionsForChangeStatusToZero = this.paymentContractOptions
        .filter(item => item.id !== this.selectedOption.id)
        .map(item => item.id)

      await this.updateOptionVisibility(optionsForChangeStatusToZero, 0)
      await this.updateOptionVisibility(this.selectedOption.id, 1)
      await this.updatePaymentContractData()
      this.isLoading = false
    },
    async setDefaultPaymentOptionParameters() {
      this.isLoading = true
      if (this.applicationPaymentOption) {
        const optionsForChangeStatusToZero = this.paymentContractOptions
          .filter(item => item.id !== this.selectedOption.id)
          .map(item => item.id)
        await this.updateOptionVisibility(optionsForChangeStatusToZero, 0)
      }
      await this.deleteUserSelectedOptionIfExist()
      await this.updatePaymentContractData()
      this.$bvModal.hide('default-payment-option')
      this.isLoading = false
    },
    async resetSelectedOption() {
      this.isLoading = true
      await this.deleteUserSelectedOptionIfExist()

      const optionsForReset = this.paymentContractOptions.map(item => item.id)
      await this.updateOptionVisibility(optionsForReset, 1)
      await this.updatePaymentContractData()
      this.selectedOption = null
      this.confirmationModalShow = false
      this.isLoading = false
    },
    addCustomOption() {
      this.isAddingCustomOption = true
    },
  },
}
</script>
