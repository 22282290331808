<template>
  <div>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-3"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center mb-2">
          <h2 class="mr-2 mb-0">
            Application
          </h2>
          <h4 class="d-flex">
            <b-badge
              pill
              :variant="applicationData.has_payment_failed ? 'light-danger' : applicationData.has_payments ? 'light-success' : 'light-primary'"
            >
              {{ applicationData.has_payment_failed ? "Payment Failed" : applicationData.has_payments ? "Payment Successful" : "Payment Pending" }}
            </b-badge>
            <feather-icon
              v-if="canUpdateApplicationStatus"
              v-b-modal.change-payment-status
              class="text-primary ml-1"
              icon="EditIcon"
              size="21"
            />
          </h4>
        </div>
        <div class="d-flex align-items-center mb-2">
          <div
            v-if="canUpdateApplicationData"
            v-b-modal.application-sharing-modal
            class="text-primary cursor-pointer mr-2"
          >
            Sharing settings
            <feather-icon
              icon="Share2Icon"
              size="21"
            />
          </div>

          <login-as-user
            v-if="canLoginAsUser"
            :user-id="userInfo.id"
            :user-role="userInfo.role.name"
          />

          <SquareIcon
            v-if="canDeleteApplication"
            size="21"
            icon="Trash2Icon"
            class="cursor-pointer"
            @onIconClick="confirmationDeletingModalHide = true"
          />
        </div>
      </div>

      <b-row>
        <b-col cols="12">
          <UserSummaryCard
            :can-update-application-status="canUpdateApplicationStatus"
            :can-update-application-session="canEditApplicationSession"
            :can-edit-application-tags="canEditApplicationTags"
          />
        </b-col>
      </b-row>

      <b-tabs
        ref="tabs"
        v-model="tabIndex"
        pills
        nav-class="bg-light mb-0 flex-nowrap navigate-tabs"
        nav-wrapper-class="bg-white position-relative wrapper-nav"
        lazy
      >
        <template #tabs-start>
          <b-nav-item
            href="#"
            role="presentation"
            class="tabs-left-button scroller-left"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="26"
              class="text-primary"
              @click.prevent="swipeLeft"
            />
          </b-nav-item>
        </template>

        <template #tabs-end>
          <b-nav-item
            href="#"
            role="presentation"
            class="tabs-right-button scroller-right"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="26"
              class="text-primary"
              @click.prevent="swipeRight"
            />
          </b-nav-item>
        </template>

        <b-tab
          title-link-class="h-100"
          v-if="userInfo.role.name !== 'child'"
        >
          <template #title>
            <span>{{ userType }} Information</span>
          </template>
          <UserInformationCard
            :is-loading="isLoading"
            :user-data="userInfo"
            @updateUserInfo="updateUserInfo"
          />
        </b-tab>

        <b-tab
          v-else-if="camperInfoTabData.length"
          title-link-class="h-100"
        >
          <template #title>
            <span>{{ userType }} Information</span>
          </template>
          <UserInformationTab
            :is-loading="isLoading"
            :camper-info-tab-data="camperInfoTabData"
          />
        </b-tab>

        <b-tab
          title="Application Details"
          title-link-class="h-100"
        >
          <b-card>
            <template #header>
              <h4
                class="d-flex align-items-center mb-0"
              >
                Application Details
              </h4>

              <div
                class="d-flex justify-content-between"
              >
                <!-- Search -->
                <div class="d-flex align-items-center justify-content-end">
                  <div
                    class="mr-3"
                  >
                    <b-button
                      class="mr-1"
                      variant="outline-primary"
                      @click="expandAllSteps = !expandAllSteps"
                    >
                      {{ expandAllSteps ? 'Collapse All' : 'Expand All' }}
                    </b-button>
                  </div>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 159, 67, .15)'"
                  variant="outline-primary"
                  class="mx-1"
                  @click="exportApplicationDetails"
                >
                  <span>Export to PDF</span>
                </b-button>
                <div
                  class="d-inline-flex align-items-center"
                >
                  <span class="align-middle mr-50">
                    <b-badge
                      variant="light-secondary"
                      class="align-content-center p-1"
                    >
                      {{ applicationStatusName }}
                    </b-badge>
                  </span>
                  <feather-icon
                    v-if="canUpdateApplicationStatus"
                    size="24"
                    icon="EditIcon"
                    class="cursor-pointer text-primary"
                    @click.stop="$bvModal.show('change-status')"
                  />
                </div>
              </div>
            </template>

            <template
              v-for="step in stepsList"
            >
              <ApplicationStep
                :key="step.id"
                :step="step"
                :is-visible="expandAllSteps"
              />
            </template>
          </b-card>
        </b-tab>
        <b-tab
          v-if="canShowTransactionTab"
          title="Transactions"
          title-link-class="h-100"
        >
          <Transactions
            :refetch-data="refetchData"
          />
        </b-tab>

        <!-- <b-tab
          v-if="userInfo.role.display_name !== 'Staff'"
          title="Upcoming Payments"
          title-link-class="h-100"
        >
          <PaymentPlan />
        </b-tab> -->

        <b-tab
          v-if="canShowPaymentContractTab"
          :active="$route.query.activeTab === 'payment-contract'"
          title="Payment Contract"
          title-link-class="h-100"
        >
          <PaymentContract />
        </b-tab>

        <b-tab
          v-if="canShowTaskTab"
          title="Tasks"
          title-link-class="h-100"
        >
          <application-task-list
            :show-filter-header="false"
            :show-table-name-in-header="true"
            :show-table-header="false"
            :use-bulk-actions="false"
            :use-collapse="false"
            :filter-name="'applicationId'"
            :filter-value="applicationId"
          />
        </b-tab>

        <b-tab
          title="Notes"
          title-link-class="h-100"
        >
          <UserNotes
            :user-data="userInfo"
            :application-id="applicationId"
            :show-add-comment="true"
          />
        </b-tab>

        <b-tab
          title="Communications"
          title-link-class="h-100"
        >
          <CommunicationList />
        </b-tab>

        <b-tab
          title="Available PPAs"
          title-link-class="h-100"
        >
          <AvailablePpaList />
        </b-tab>

        <b-tab
          title="Groups"
          title-link-class="h-100"
        >
          <EditUserGroups
            @updateGroups="setApplicationData"
          />
        </b-tab>

        <b-tab
          title="Documents"
          title-link-class="h-100"
        >
          <UserDocuments
            :user-data="userInfo"
            :application-id="applicationId"
          />
        </b-tab>

        <b-tab
          title="Letters"
          title-link-class="h-100"
        >
          <GenerateUserLetter
            :user-data="userInfo"
            :program-id="programId"
            :user-type="type"
          />
        </b-tab>
        <b-tab
          title="Activity Log"
          title-link-class="h-100"
        >
          <ApplicationEventsLogList />
        </b-tab>
      </b-tabs>

      <!-- Change Step Status -->
      <application-change-step-status-modal />

      <!-- Change Status -->
      <application-change-status-modal />

      <!-- Change Payment Status -->
      <application-change-payment-status-modal
        :application-id="applicationId"
        :has-payment-failed="applicationData.has_payment_failed"
        @paymentStatusChanged="setApplicationData"
      />

      <!-- Payment Failed -->
      <b-modal
        ref="payment-failed"
        centered
        ok-title="Save"
        cancel-variant="outline-secondary"
        size="lg"
        hide-footer
        hide-header
      >
        <div
          class="p-2 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            class="mb-2"
            viewBox="0 0 80 80"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.3335 39.9999C3.3335 60.3332 19.6668 76.6666 40.0002 76.6666C60.3335 76.6666 76.6668 60.3332 76.6668 39.9999C76.6668 19.6666 60.3335 3.33325 40.0002 3.33325C19.6668 3.33325 3.3335 19.6666 3.3335 39.9999ZM10.0002 39.9999C10.0002 23.3333 23.3335 9.99992 40.0002 9.99992C56.6668 9.99992 70.0002 23.3333 70.0002 39.9999C70.0002 56.6666 56.6668 69.9999 40.0002 69.9999C23.3335 69.9999 10.0002 56.6666 10.0002 39.9999ZM43.3335 39.9999V26.6666C43.3335 24.6666 42.0002 23.3333 40.0002 23.3333C38.0002 23.3333 36.6668 24.6666 36.6668 26.6666V39.9999C36.6668 41.9999 38.0002 43.3333 40.0002 43.3333C42.0002 43.3333 43.3335 41.9999 43.3335 39.9999ZM43.0002 54.6666C43.0002 54.9999 42.6668 55.3333 42.3335 55.6666C41.6668 56.3333 41.0002 56.6666 39.6668 56.6666C38.6668 56.6666 38.0002 56.3333 37.3335 55.6666C36.6668 54.9999 36.3335 54.3333 36.3335 53.3333C36.3335 52.9427 36.4479 52.6666 36.5426 52.4378C36.6096 52.2761 36.6668 52.138 36.6668 51.9999C36.6668 51.6666 37.0002 51.3333 37.3335 50.9999C38.3335 49.9999 39.6668 49.6666 41.0002 50.3333C41.1668 50.3333 41.2502 50.4166 41.3335 50.4999C41.4168 50.5832 41.5002 50.6666 41.6668 50.6666C42.0002 50.6666 42.3335 50.9999 42.3335 50.9999C42.5002 51.1666 42.5835 51.3332 42.6668 51.4999C42.7502 51.6666 42.8335 51.8332 43.0002 51.9999C43.3335 52.3333 43.3335 52.9999 43.3335 53.3333C43.3335 53.4999 43.2502 53.7499 43.1668 53.9999C43.0835 54.2499 43.0002 54.4999 43.0002 54.6666Z"
              fill="black"
            />
            <mask
              id="mask0"
              style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="3"
              y="3"
              width="74"
              height="74"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.3335 39.9999C3.3335 60.3332 19.6668 76.6666 40.0002 76.6666C60.3335 76.6666 76.6668 60.3332 76.6668 39.9999C76.6668 19.6666 60.3335 3.33325 40.0002 3.33325C19.6668 3.33325 3.3335 19.6666 3.3335 39.9999ZM10.0002 39.9999C10.0002 23.3333 23.3335 9.99992 40.0002 9.99992C56.6668 9.99992 70.0002 23.3333 70.0002 39.9999C70.0002 56.6666 56.6668 69.9999 40.0002 69.9999C23.3335 69.9999 10.0002 56.6666 10.0002 39.9999ZM43.3335 39.9999V26.6666C43.3335 24.6666 42.0002 23.3333 40.0002 23.3333C38.0002 23.3333 36.6668 24.6666 36.6668 26.6666V39.9999C36.6668 41.9999 38.0002 43.3333 40.0002 43.3333C42.0002 43.3333 43.3335 41.9999 43.3335 39.9999ZM43.0002 54.6666C43.0002 54.9999 42.6668 55.3333 42.3335 55.6666C41.6668 56.3333 41.0002 56.6666 39.6668 56.6666C38.6668 56.6666 38.0002 56.3333 37.3335 55.6666C36.6668 54.9999 36.3335 54.3333 36.3335 53.3333C36.3335 52.9427 36.4479 52.6666 36.5426 52.4378C36.6096 52.2761 36.6668 52.138 36.6668 51.9999C36.6668 51.6666 37.0002 51.3333 37.3335 50.9999C38.3335 49.9999 39.6668 49.6666 41.0002 50.3333C41.1668 50.3333 41.2502 50.4166 41.3335 50.4999C41.4168 50.5832 41.5002 50.6666 41.6668 50.6666C42.0002 50.6666 42.3335 50.9999 42.3335 50.9999C42.5002 51.1666 42.5835 51.3332 42.6668 51.4999C42.7502 51.6666 42.8335 51.8332 43.0002 51.9999C43.3335 52.3333 43.3335 52.9999 43.3335 53.3333C43.3335 53.4999 43.2502 53.7499 43.1668 53.9999C43.0835 54.2499 43.0002 54.4999 43.0002 54.6666Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0)">
              <rect
                width="80"
                height="80"
                fill="#FF9F43"
              />
            </g>
          </svg>
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Missing Payment Method
          </b-card-title>
          <b-card-text class="mb-2">
            The application is missing Credit Card information, all charges will have to be inputted manually
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(255, 159, 67, .15)'"
            variant="outline-warning"
            class="mx-1"
            @click="hideModal"
          >
            <span>Cancel</span>
          </b-button>
        </div>
      </b-modal>

      <EditSessionsModal
        :program-id="programId"
        :application-id="applicationId"
        :sessions-list="sessionsList"
        @updateSessions="setApplicationData"
      />

      <confirmation-modal
        :toggle-modal="confirmationChangeStatusModalHide"
        :title="'Are you sure'"
        :message="'The manual status change will exclude this application from the automated status change based on status completion. ' +
          'You can do the same by marking the steps as completed or rejected. Would you like to proceed?'"
        @close-modal="confirmationChangeStatusModalHide = false"
        @confirm="confirmUpdatingStatusManual"
      />

      <confirmation-modal
        :toggle-modal="confirmationDeletingModalHide"
        :type="'-application-deleting'"
        :title="'Are you sure'"
        :message="`Are you sure you want to permanently delete this application(${userInfo.full_name})?`"
        :is-loading="isAppDeleteLoading"
        @close-modal="confirmationDeletingModalHide = false"
        @confirm="deleteApplication"
      />

      <application-sharing-modal
        :application-id="applicationId"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTabs,
  BTab,
  BNavItem,
  BRow,
  BCol,
  BButton,
  BBadge,
  BCard,
  BCardText,
  BCardTitle,
  BSpinner,
  VBModal,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import { setActiveTab } from '@core/mixins/setActiveTab'

import Ripple from 'vue-ripple-directive'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import store from '@/store'
import storeModule from '@/views/admin/applications/new-application-edit/applicationEditStoreModule'
import useApplicationsList from '@/views/admin/applications/applications-list/useApplicationsList'

import useStaffProfile from '@/views/admin/staff/staff-profile/useStaffProfile'

import router from '@/router'

import UserSummaryCard from '@/views/components/user-summary-card/UserSummaryCard.vue'
import UserInformationCard from '@/views/components/user-information-card/UserInformationCard.vue'
import UserInformationTab from '@/views/components/user-information-tab/UserInformationTab.vue'
import ApplicationStep from '@/views/components/application-step/ApplicationStep.vue'
import UserNotes from '@/views/components/user-notes/UserNotes.vue'
import EditUserGroups from '@/views/components/edit-user-groups/EditUserGroups.vue'
import CommunicationList from '@/views/components/application-communication/CommunicationList.vue'
import Transactions from '@/views/admin/transactions-v2/transactions-list/TransactionsList.vue'
import PaymentContract from '@/views/components/application-payment-contract-v2/PaymentContract.vue'
import UserDocuments from '@/views/components/user-documents/UserDocuments.vue'
import GenerateUserLetter from '@/views/components/generate-user-letter/GenerateUserLetter.vue'
import AvailablePpaList from '@/views/components/application-available-ppa/AvailablePpaList.vue'
import ApplicationChangeStatusModal from '@/views/components/application/ApplicationChangeStatusModal.vue'
import ApplicationChangeStepStatusModal from '@/views/components/application/ApplicationChangeStepStatusModal.vue'
import EditSessionsModal from '@/views/components/edit-sessions-modal/EditSessionsModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import LoginAsUser from '@/views/components/login-as-user/LoginAsUser.vue'
import ApplicationChangePaymentStatusModal from '@/views/components/application/ApplicationChangePaymentStatusModal.vue'
import ApplicationSharingModal from '@/views/components/application-sharing-modal/ApplicationSharingModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ApplicationEventsLogList from '@/views/components/application-events-log/ApplicationEventsLogList.vue'
import ApplicationTaskList from '@/views/admin/tasks/application-task-list/ApplicationTaskList.vue'

export default {
  components: {
    SquareIcon,
    BTabs,
    BTab,
    BNavItem,
    BRow,
    BCol,
    BButton,
    BBadge,
    BCard,
    BCardText,
    BCardTitle,
    BSpinner,

    UserSummaryCard,
    UserInformationCard,
    UserInformationTab,
    ApplicationStep,
    UserNotes,
    EditUserGroups,
    CommunicationList,
    Transactions,
    PaymentContract,
    UserDocuments,
    GenerateUserLetter,
    AvailablePpaList,
    ApplicationChangeStatusModal,
    ApplicationChangeStepStatusModal,
    EditSessionsModal,
    ConfirmationModal,
    LoginAsUser,
    ApplicationChangePaymentStatusModal,
    ApplicationSharingModal,
    ApplicationTaskList,
    ApplicationEventsLogList,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [formBuilderPreparations, setActiveTab, downloadExportFile],
  data() {
    return {
      tabIndex: 0,
      ready: false,
      confirmationDeletingModalHide: false,
      isAppDeleteLoading: false,
      expandAllSteps: false,
      refetchData: false,
      permissionSubjects,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-application-edit'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      deleteItem,
      exportApplicationToPdf,
      updateCamper,
    } = useApplicationsList()

    const {
      updateStaff,
    } = useStaffProfile()

    return {
      deleteItem,
      exportApplicationToPdf,
      updateCamper,
      updateStaff,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-application-edit/getIsLoading',
      userInfo: 'app-application-edit/getUserInfo',
      camperInfoTabData: 'app-application-edit/getCamperInfoTabData',
      camper: 'app-application-edit/getCamper',
      applicationId: 'app-application-edit/getApplicationId',
      applicationData: 'app-application-edit/getApplicationData',
      programId: 'app-application-edit/getProgramId',
      type: 'app-application-edit/getType',
      stepsList: 'app-application-edit/getStepsList',
      statusesList: 'app-application-edit/getStatusesList',
      sessionsList: 'app-application-edit/getSessionsList',
      applicationStatusName: 'app-application-edit/getApplicationStatusName',
      statusesLoadingStatus: 'app-application-edit/getStatusesLoadingStatus',
      paymentContract: 'app-application-edit/getPaymentContract',
      newSelectedStatus: 'app-application-edit/getNewSelectedStatus',
      isButtonDisabled: 'app-application-edit/getIsSaveButtonDisabled',
    }),
    confirmationChangeStatusModalHide: {
      get() {
        return store.getters['app-application-edit/getConfirmationChangeStatusModalHide']
      },
      set(val) {
        store.commit('app-application-edit/SET_CONFIRMATION_CHANGE_STATUS', val)
      },
    },
    userType() {
      return this.type
    },
    canLoginAsUser() {
      if (this.userInfo.role.display_name === 'Staff') {
        return this.$can('read', this.permissionSubjects.LoginAsStaff)
      }
      return this.$can('read', this.permissionSubjects.LoginAsChild)
    },
    canUpdateApplicationStatus() {
      if (this.userInfo.role.display_name === 'Staff') {
        return this.$can('update', this.permissionSubjects.StaffApplicationStatus)
      }
      return this.$can('update', this.permissionSubjects.ChildApplicationStatus)
    },
    canEditApplicationSession() {
      if (this.userInfo.role.display_name === 'Staff') {
        return this.$can('update', this.permissionSubjects.StaffApplicationSession)
      }
      return this.$can('update', this.permissionSubjects.ChildApplicationSession)
    },
    canEditApplicationTags() {
      return this.$can('update', this.permissionSubjects.Tags)
    },
    canUpdateApplicationData() {
      if (this.userInfo.role.display_name === 'Staff') {
        return this.$can('update', this.permissionSubjects.StaffApplicationData)
      }
      return this.$can('update', this.permissionSubjects.ChildApplicationData)
    },
    canShowPaymentContractTab() {
      return this.$can('read', this.permissionSubjects.PaymentContract)
        && this.paymentContract && this.userInfo.role.display_name !== 'Staff'
    },
    canShowTaskTab() {
      return this.$can('read', this.permissionSubjects.Tasks)
    },
    canShowTransactionTab() {
      return this.$can('read', this.permissionSubjects.Transaction) && this.userInfo.role.display_name !== 'Staff'
    },
    canDeleteApplication() {
      if (this.userInfo.role.display_name === 'Staff') {
        return this.$can('delete', this.permissionSubjects.StaffApplication)
      }
      return this.$can('delete', this.permissionSubjects.ChildApplication)
    },
  },
  watch: {
    tabIndex(val) {
      if (!this.ready) return
      this.setTabIndexQuery(val)
    },
    $route() {
      this.setFilterParams()
    },
  },
  async created() {
    store.commit('app-application-edit/SET_APPLICATION_ID', router.currentRoute.params.id)
    store.commit('app-application-edit/SET_TYPE', router.currentRoute.params.type)
    store.commit('app-application-edit/SET_IS_LOADING', true)
    if (this.applicationId) {
      await this.setApplicationData()
      await this.prepareStatuses()
      await store.dispatch('app-application-edit/fetchApplicationAutoAssignedGroups')
    }
    store.commit('app-application-edit/SET_IS_LOADING', false)
    this.ready = true
  },
  mounted() {
    this.setActiveTab()
  },
  methods: {
    setFilterParams() {
      const query = { ...this.$route.query }
      this.tabIndex = Number(query.tab_index || 0)
    },
    async setApplicationData() {
      await store.dispatch('app-application-edit/fetchApplication')
      this.refetchData = !this.refetchData
    },
    async prepareStatuses() {
      await store.dispatch('app-application-edit/fetchApplicationStatuses')
    },
    hideModal() {
      this.$bvModal.hide('change-status')
    },
    async confirmUpdatingStatusManual() {
      store.commit('app-application-edit/SET_CONFIRMATION_CHANGE_STATUS', false)
      const payload = {
        status: this.newSelectedStatus,
        withoutCharging: false,
        vm: this,
      }
      await this.$store.dispatch('app-application-edit/updApplicationStatus', payload)
    },
    async updateUserInfo() {
      const queryParams = {
        user: this.userInfo,
      }
      if (['Camper', 'Student'].includes(this.type)) {
        queryParams.id = this.camper.id
        await this.updateCamper(queryParams).then(() => {
          store.dispatch('app-application-edit/fetchApplication')
        })
        return
      }
      await this.updateStaff(queryParams).then(() => {
        store.dispatch('app-application-edit/fetchApplication')
      })
    },
    async deleteApplication() {
      try {
        this.isAppDeleteLoading = true
        await this.deleteItem(this.applicationId)
        this.$router.push({ name: 'admin-applications', params: { type: this.type } })
      } finally {
        this.isAppDeleteLoading = false
      }
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft
      // Condition to check if scrolling is required
      if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
        // Get the start timestamp
        const startTime = 'now' in window.performance
          ? performance.now()
          : new Date().getTime()

        // eslint-disable-next-line no-inner-declarations
        function scroll(timestamp) {
          // Calculate the timeelapsed
          const timeElapsed = timestamp - startTime
          // Calculate progress
          const progress = Math.min(timeElapsed / duration, 1)
          // Set the scrolleft
          // eslint-disable-next-line no-param-reassign
          element.scrollLeft = scrollPos + scrollPixels * progress
          // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            // Request for animation
            window.requestAnimationFrame(scroll)
          }
        }
        // Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll)
      }
    },
    swipeLeft() {
      const tabs = document.querySelector('.navigate-tabs')
      this.scrollTo(tabs, -300, 500)
    },
    swipeRight() {
      const tabs = document.querySelector('.navigate-tabs')
      this.scrollTo(tabs, 300, 500)
    },
    async exportApplicationDetails() {
      await this.exportApplicationToPdf(this.applicationId).then(response => {
        if (response.status === 200) {
          this.downloadFile(response.data, `${this.userInfo.full_name}(application details)`, 'pdf')
        }
      })
    },
  },
}
</script>

<style lang="scss">
.tabs-right-button, .tabs-left-button {
  position: absolute !important;
  top: calc(50% - 12px);

  a {
    padding: 0 !important;
  }
  svg {
    margin-right: 0 !important;
  }
}
.tabs-right-button {
  right: 10px;
}
.tabs-left-button {
  left: 10px;
}
.wrapper-nav {
  padding: 20px 50px 0;
}
.navigate-tabs{
  overflow-x: hidden;
}
</style>
